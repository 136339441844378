import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  CandidateHiringStageRow,
  StagesWidgetRowOptions,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import { StageChildTitle } from '@src/pages/Forms/Candidate/StagesWidget/components/StageChildTitle'
import { StageChildResult } from '@src/pages/Forms/Candidate/StagesWidget/components/StageChildResult'
import { StageChildRating } from '@src/pages/Forms/Candidate/StagesWidget/components/StageChildRating'
import { StageTitle } from '@src/pages/Forms/Candidate/StagesWidget/components/StageTitle'
import StageActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StageActions'
import { isChildRow } from '@src/pages/Forms/Candidate/StagesWidget/utils'
import { Flex } from '@revolut/ui-kit'

export const createCandidateHiringStagesTitle = ({
  round,
}: StagesWidgetRowOptions): ColumnInterface<CandidateHiringStageRow> => ({
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return (
        <StageChildTitle
          candidate={data.candidate}
          feedback={data.feedback}
          stage={data.stage}
        />
      )
    }
    return <StageTitle round={round} stage={data.stage} />
  },
})

export const candidateHiringStagesRecommendation: ColumnInterface<CandidateHiringStageRow> =
  {
    type: CellTypes.insert,
    idPoint: 'recommendation',
    dataPoint: 'recommendation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Recommendation',
    insert: ({ data }) => {
      if (isChildRow(data)) {
        if (data.candidate) {
          return null
        }
        return <StageChildResult feedback={data.feedback} stage={data.stage} />
      }
      return null
    },
  }

export const createCandidateHiringStagesRating = ({
  round,
  candidate,
  stages,
  canAddFeedback,
  canCancel,
  canViewEditOffer,
  disableActions,
  onRefresh,
  onClick,
}: StagesWidgetRowOptions): ColumnInterface<CandidateHiringStageRow> => ({
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rating',
  headerAlign: 'right',
  insert: ({ data }) => {
    if (isChildRow(data)) {
      if (data.candidate) {
        return null
      }
      return (
        <Flex justifyContent="flex-end" pr="s-8">
          <StageChildRating feedback={data.feedback} stage={data.stage} />
        </Flex>
      )
    }
    const isArchived = !!round?.archived_reason
    const showActions = !disableActions && (canCancel || canAddFeedback) && !isArchived
    if (!showActions) {
      return null
    }
    return (
      <Flex justifyContent="flex-end" pr="s-8">
        <StageActions
          canAddFeedback={canAddFeedback}
          canCancel={canCancel}
          candidateId={candidate.id}
          canViewEditOffer={canViewEditOffer}
          currentStageId={round?.latest_interview_stage?.id}
          menuType="dropdown"
          roundId={round.id}
          stage={data.stage}
          stages={stages}
          onOpenSidebar={(stageData, mode, actionType) => {
            onClick?.(stageData, mode, undefined, actionType)
          }}
          onRefresh={onRefresh}
        />
      </Flex>
    )
  },
})
