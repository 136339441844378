import { SelectOptionItemType } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import {
  CandidateHiringStageRow,
  CandidateHiringStageChildRow,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  CandidateInterface,
} from '@src/interfaces/interviewTool'

export const isChildRow = (
  data: CandidateHiringStageRow,
): data is CandidateHiringStageChildRow => !('children' in data)

export type NewOption = Id<'new'>
export type OptionType = InterviewRoundInterface | NewOption
export type SelectOptionType = SelectOptionItemType<OptionType>

export const isNewOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<NewOption> => String(option.key) === 'new'

export const isInterviewRound = (
  option: SelectOptionType,
): option is SelectOptionItemType<InterviewRoundInterface> => String(option.key) !== 'new'

export const mapStageToCandidateHiringStageRow = (
  stage: InterviewStageWithoutRoundInterface,
  candidate?: CandidateInterface,
): CandidateHiringStageRow => {
  let children: CandidateHiringStageChildRow[] = []
  if (stage.interview_feedbacks.length) {
    stage.interview_feedbacks.forEach(feedback => {
      children.push({ feedback, stage })
    })
  }
  if (stage.online_test && stage.online_test_result) {
    children.push({ stage })
  }
  const candidateSchedulingStatuses = ['pending_candidate_response', 'scheduling_expired']
  if (candidateSchedulingStatuses.includes(stage.scheduling_status)) {
    children.push({ candidate, stage })
  }
  return {
    stage,
    children,
  }
}
