import { Avatar, HStack, Text, Token, VStack } from '@revolut/ui-kit'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { UserLink } from '@src/components/UserWithAvatar/UserLink'
import React from 'react'
import {
  CandidateInterface,
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { StageChildInfo } from '@src/pages/Forms/Candidate/StagesWidget/components/StageChildInfo'
import { getInitials } from '@src/utils/employees'
import { formatTimeAgo } from '@src/utils/format'

type StageChildTitleProps = {
  candidate?: CandidateInterface
  feedback?: InterviewFeedbackInterface
  stage: InterviewStageWithoutRoundInterface
}

export const StageChildTitle = ({ candidate, feedback, stage }: StageChildTitleProps) => {
  let info: React.ReactNode = <StageChildInfo feedback={feedback} stage={stage} />
  let avatar: React.ReactNode = null
  let text: React.ReactNode = null

  if (candidate) {
    avatar = <Avatar size={40}>{getInitials(candidate.full_name)}</Avatar>
    text = (
      <Text variant="emphasis1" color={Token.color.orange}>
        {stage.scheduling_status === 'pending_candidate_response' &&
          `Pending ${candidate.full_name} response`}
        {stage.scheduling_status === 'scheduling_expired' && 'Interview slots expired'}
      </Text>
    )
    info = stage.updated_date_time ? (
      <Text variant="body2">
        Interview slots sent {formatTimeAgo(stage.updated_date_time)}
      </Text>
    ) : null
  }

  if (feedback) {
    const { interviewer = {} } = feedback
    avatar = <UserAvatar {...interviewer} size={40} />
    text = (
      <UserLink
        {...interviewer}
        userNameProps={{
          textProps: { variant: 'emphasis1' },
          ellipsisProps: { maxWidth: '200px' },
        }}
      />
    )
  }

  if (stage.online_test && stage.online_test_result) {
    avatar = <Avatar size={40} useIcon="Laptop" />
    text = <Text variant="emphasis1">{stage.online_test.name}</Text>
  }

  if (avatar && text) {
    return (
      <HStack gap="s-8">
        {avatar}
        <VStack>
          {text}
          {info}
        </VStack>
      </HStack>
    )
  }
  return null
}
