import React, { useEffect } from 'react'
import { ThemeProvider, Box, Absolute, Flex } from '@revolut/ui-kit'
import { GlobalStyle } from './styles'
import { ROUTES } from '@src/constants/routes'
import { Hero } from '@src/pages/Landing/components/Hero'

import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { pathToUrl } from '@src/utils/router'
import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { Footer } from '@src/pages/Landing/components/Footer'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

export const ContactUs = () => {
  const { sendAnalyticsEvent } = useAnalytics()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box pb="s-16">
      <ThemeProvider mode="dark">
        <GlobalStyle isContactUsForm />
        <Hero isContactUsForm />

        <Absolute top={0} width="100%">
          <Flex
            maxWidth={{ all: '1000px', xxl: '1432px' }}
            width="100%"
            margin="auto"
            justifyContent="space-between"
            px="s-16"
            mt="s-16"
            alignItems="center"
          >
            <LogoAlt height="40" width="200" />
            <Button
              variant="primary"
              use={InternalLink}
              to={pathToUrl(ROUTES.SIGNUP.MAIN)}
              onClick={() => {
                sendAnalyticsEvent(AnalyticsEvents.click_signup)
              }}
            >
              Sign up
            </Button>
          </Flex>
        </Absolute>

        <Footer />
      </ThemeProvider>
    </Box>
  )
}
