import React from 'react'
import { Tag, Text, Token } from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import upperFirst from 'lodash/upperFirst'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'

type StageChildRatingProps = {
  feedback?: InterviewFeedbackInterface
  stage: InterviewStageWithoutRoundInterface
}

export const StageChildRating = ({ feedback, stage }: StageChildRatingProps) => {
  let rating = 'n/a'
  if (feedback && feedback.rating) {
    rating = upperFirst(convertOperators(feedback.rating))
  }
  if (stage.online_test_result?.percentage_score) {
    rating = `${stage.online_test_result.percentage_score}%`
  }
  if (rating === 'n/a') {
    return (
      <Text variant="emphasis2" color={Token.color.greyTone50}>
        {rating}
      </Text>
    )
  }
  return (
    <Tag color={Token.color.greyTone8} style={{ color: Token.color.greyTone50 }}>
      {rating}
    </Tag>
  )
}
