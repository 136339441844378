import { chain, HStack, Icon, Text, Token } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import React from 'react'

type StageTitleProps = {
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
}

export const CurrentStage = () => {
  return (
    <HStack align="center" color={Token.color.accent} gap="s-8">
      <Icon name="Time" size={12} />
      <Text variant="emphasis4">Current stage</Text>
    </HStack>
  )
}

export const StageTitle = ({ round, stage }: StageTitleProps) => {
  const title = (
    <Text variant="emphasis1" wrap="nowrap">
      {stage.title}
    </Text>
  )
  if (round?.latest_interview_stage?.id !== stage.id) {
    return title
  }
  return (
    <HStack gap="s-4" align="center">
      {chain(title, <CurrentStage />)}
    </HStack>
  )
}
