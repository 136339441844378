import React from 'react'
import { HStack, Icon, IconName, Text, Token } from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'

type StageChildResultProps = {
  feedback?: InterviewFeedbackInterface
  stage: InterviewStageWithoutRoundInterface
}

type ResultProps = {
  color: string
  iconName?: IconName
  text: string
}

const getFeedbackProps = (feedback: InterviewFeedbackInterface): ResultProps => {
  const { result, status } = feedback
  let iconName: undefined | IconName
  let color = Token.color.greyTone50
  let text = 'n/a'
  if (status === 'rejected') {
    color = Token.color.greyTone50
    text = 'n/a'
  } else if (status === 'completed') {
    switch (result) {
      case 'hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'strong_hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'no_hire':
        iconName = 'SocialDislike'
        color = Token.color.red
        break
      case 'strong_no_hire':
        iconName = 'SocialDislike'
        color = Token.color.red
        break
      default:
        break
    }
    text = result ? removeUnderscore(upperFirst(result)) : ''
  }
  return {
    color,
    iconName,
    text,
  }
}

const getTestProps = (stage: InterviewStageWithoutRoundInterface): ResultProps => {
  if (!stage.online_test_result || stage.online_test_result?.is_passed === null) {
    return {
      color: Token.color.greyTone50,
      text: 'n/a',
    }
  }
  if (stage.online_test_result?.is_passed) {
    return {
      color: Token.color.success,
      iconName: 'SocialLike',
      text: 'Pass',
    }
  }
  return {
    color: Token.color.red,
    iconName: 'SocialDislike',
    text: 'Fail',
  }
}

export const StageChildResult = ({ feedback, stage }: StageChildResultProps) => {
  const { color, iconName, text } = feedback
    ? getFeedbackProps(feedback)
    : getTestProps(stage)
  return (
    <HStack align="center" gap="s-4" margin="auto">
      {iconName && <Icon color={color} name={iconName} size={16} />}
      <Text color={color} variant="body1" style={{ whiteSpace: 'nowrap' }}>
        {text}
      </Text>
    </HStack>
  )
}
